//该文件专门创建整个应用的路由器
import VueRouter from 'vue-router'

//创建并暴露一个路由器
export default new VueRouter({
  routes: [
    // 首页
    {
      path: '/',
      redirect: '/comprehensiveScene'
    },
  
    {
      path: '/comprehensiveScene',
      component: () => import('../views/comprehensiveScene.vue')
    }
    
  ]
})
