<template>
  <v-scale-screen width="1920" height="1080">
    <div id="app">
      <router-view />
    </div>
  </v-scale-screen>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
</style>
