import Vue from 'vue'
import App from './App.vue'

// import '@/css/common.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import router from './router'

import * as echarts from 'echarts'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
// 第一步: 引入router 插件
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)

new Vue({
  router,

  render: h => h(App)
}).$mount('#app')
